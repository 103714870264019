<template>
  <div id="NotebookDashboard">

    <a-button class="button" type="primary" @click="this.newFilter()">
      New
    </a-button>

    <a-button class="button" type="primary" @click="this.newFilterVersion()">
      New version
    </a-button>

    <a-button class="button" type="primary" @click="this.compileVersion()">
      Compile Version
    </a-button>

    <a-button class="button" type="primary" @click="this.publishVersion()">
      Publish Version
    </a-button>


    <a-popconfirm
        title="Are you sure unpublish this filter?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="this.unPublishVersion()"
    >
      <a-button class="button" type="primary">
        Unpublish Version
      </a-button>
    </a-popconfirm>

    <a-popconfirm
        title="Are you sure delete this notebook?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="removeNotebook"
    >
      <a-button class="button" id="remove" type="primary">
        Remove
      </a-button>
    </a-popconfirm>



    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="notebooks"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="selectNotebookVisible"
        :closable=false
        title="Select Notebook"
        @ok="okSelectNotebook">
      <a-form
          v-model:model="formSelectNotebook"
          name="form_select_notebook"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Name">
          <a-input v-model:value="formSelectNotebook.name" />
        </a-form-item>

        <a-form-item label="Versions">
          <a-select
              v-model:value="selectedVersion"
              style="width: 150px"
              :options="versionsOptions"
          ></a-select>
        </a-form-item>


      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="newFilterVisible"
        :closable=false
        title="New Filter"
        @ok="okNewFilter">
      <a-form
          v-model:model="formNewFilter"
          name="form_new_filter"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Name">
          <a-input 
            v-model:value="formNewFilter['name']" 
            placeholder="SPPLN_"
          />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formNewFilter['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="importNotebookVisible"
        :closable=false
        title="Import and new procedure"
        @ok="okImportNotebook">
      <a-form
          v-model:model="formImportNotebook"
          name="form_import_procedure"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Source Procedure">
          <a-select
              v-model:value="formImportNotebook['procedure']"
              show-search
              allowClear
              :filter-option="filterOption"
              style="width: 150px"
              :options="notebooksFromProcedures"
          ></a-select>
        </a-form-item>

        <a-form-item label="Source Version">
          <a-input v-model:value="formImportNotebook['version']" />
        </a-form-item>

        <a-form-item label="New Name">
          <a-input v-model:value="formImportNotebook['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formImportNotebook['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="importTemplateVisible"
        :closable=false
        title="Importe Template"
        @ok="okImportTemplate">
      <a-form
          v-model:model="formImportTemplate"
          name="form_import_template"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Notebook">
          <a-select
              v-model:value="formImportTemplate['notebook']"
              show-search
              allowClear
              :filter-option="filterOption"
              style="width: 150px"
              :options="storeTemplates.templatesOptions"
          ></a-select>
        </a-form-item>


        <a-form-item label="Name">
          <a-input v-model:value="formImportTemplate['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formImportTemplate['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {apiUrl, TLSEnabled, zeppelinApi} from "@/config/api";
import {reactive} from "vue";
import {notification} from 'ant-design-vue';
import {clusterStore} from "@/store/ClusterStore";
import {userStore} from "@/store/UserStore";
import {getClusters, getTemplates, getUsers} from "@/helpers/RefreshQueries";
import {templateStore} from "@/store/TemplateStore";

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {
    const storeClusters = clusterStore()
    const storeUsers = userStore()
    const storeTemplates = templateStore()
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {storeClusters, storeUsers, storeTemplates, filterOption}
  },
  data() {
    return {
      compilingFilterId: null,
      versionsOptions: [],
      selectedVersion: null,
      selectedNotebook: null,
      selectedIdNotebook: null,
      notebooks: [],
      notebooksFromUser: [],
      notebooksFromProcedures: [],
      procedures: [],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellDoubleClicked: this.onCellDoubleClicked,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true
      },
      columns: [
        {
          headerName: 'name',
          width: 400,
          field: 'name',
          sortable: true,
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset'], debounceMs: 0, applyButton: false},
          checkboxSelection: true
        },
        {
          headerName: 'version',
          width: 120,
          field: 'version',
          sortable: true,
          filterParams: {buttons: ['reset'],debounceMs: 0, applyButton: false},
          floatingFilter: true,
        },
        {
          headerName: 'cluster',
          width: 200,
          field: 'cluster',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'compiled',
          width: 120,
          field: 'compiled',
          cellRenderer: params => {
            // If this row is currently being compiled, show progress bar
            if (params.data.id === this.compilingFilterId) {
              return `<div class="compilation-progress">
                        <div class="ant-spin ant-spin-spinning">
                          <span class="ant-spin-dot ant-spin-dot-spin">
                            <i class="ant-spin-dot-item"></i>
                            <i class="ant-spin-dot-item"></i>
                            <i class="ant-spin-dot-item"></i>
                            <i class="ant-spin-dot-item"></i>
                          </span>
                          <div class="ant-spin-text">Compiling...</div>
                        </div>
                      </div>`;
            }
            // Otherwise show the checkbox
            return `<input type='checkbox' ${params.value ? 'checked' : 'unchecked'} style="pointer-events: none;" />`;
          },
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'published',
          width: 120,
          field: 'published',
          cellRenderer: params => {
            return `<input type='checkbox' ${params.value ? 'checked' : 'unchecked'} style="pointer-events: none;" />`;
          },
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }

      ],
      formNewFilter: reactive({}),
      formImportNotebook: reactive({}),
      formImportTemplate: reactive({}),
      formSelectNotebook: reactive({}),
      roles: [],
      selectNotebookVisible: false,
      newFilterVisible: false,
      importNotebookVisible: false,
      importTemplateVisible: false
    }
  },
  created() {
    this.refreshQuery()
  },
  methods: {

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
      params.api.setFilterModel({
        name: { filterType: 'text', type: 'contains', filter: '' }
      });
    },
    removeNotebook() {
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes.length > 0) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/procedures/" + node.data.name + "/version/" + node.data.version + "/id/" + node.data.id
        const token = "Bearer " + this.keycloak.token
        fetch(uri, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (response.ok) {
                this.refreshQuery()
              }
              else {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
            })
            .catch(error => {
              try {
                const errorObj = JSON.parse(error);
                this.errorNotification(errorObj.error || error);
              } catch (e) {
                this.errorNotification(error);
              }
            })
      } else {
        this.warningNotification("no filter selected")
      }
    },
    newFilterVersion(){
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes.length > 0) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/procedures/notebook/newversion/" + node.data.id
        const token = "Bearer " + this.keycloak.token
        const cloneNotebook = {
          name: node.data.name,
          path: "procedures/" + node.data.name,
          cluster: node.data.cluster
        }
        fetch(uri, {
          method: "POST",
          body: JSON.stringify(cloneNotebook),
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (!response.ok) {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
              else {
                this.refreshQuery()
              }

            })
            .catch(error => {
              try {
                const errorObj = JSON.parse(error);
                this.errorNotification(errorObj.error || error);
              } catch (e) {
                this.errorNotification(error);
              }
            })
      } else {
        this.warningNotification("no filter selected")
      }
      this.gridApi.selectedNodes = false
    },
    compileVersion(){
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes.length > 0) {
        const node = selectedNodes[0]
        // Set the filter as currently compiling
        this.compilingFilterId = node.data.id;
        // Refresh the grid to show the progress bar
        this.gridApi.refreshCells({
          force: true,
          rowNodes: [node]
        });
        
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/procedures/compile/" + node.data.name + "/version/" + node.data.version
        const token = "Bearer " + this.keycloak.token
        const procedureNotebook = {
          name: node.data.name,
          version: node.data.version,
          cluster: node.data.cluster,
          id: node.data.id,
          compiled: node.data.compiled,
          published: node.data.published
        }
        fetch(uri, {
          method: "POST",
          body: JSON.stringify(procedureNotebook),
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (!response.ok) {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
              else {
                this.infoNotification("version compiled Ok")
                // Reset compilation state and refresh
                this.compilingFilterId = null;
                if (this.gridApi) {
                  const node = this.gridApi.getSelectedNodes()[0];
                  if (node) {
                    this.gridApi.refreshCells({
                      force: true,
                      rowNodes: [node]
                    });
                  }
                }
                this.refreshQuery()
              }
            })
            .catch(error => {
              try {
                const errorObj = JSON.parse(error);
                this.errorNotification(errorObj.error || error);
              } catch (e) {
                this.errorNotification(error);
              }
              // Reset compilation state on error too
              this.compilingFilterId = null;
              if (this.gridApi) {
                  const node = this.gridApi.getSelectedNodes()[0];
                  if (node) {
                    this.gridApi.refreshCells({
                      force: true,
                      rowNodes: [node]
                    });
                  }
               }              
              this.refreshQuery();
            })
      } else {
        this.warningNotification("no filter selected")
      }
      this.gridApi.selectedNodes = false
    },
    publishVersion(){
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes.length > 0) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/procedures/publish/" + node.data.name + "/version/" + node.data.version
        const token = "Bearer " + this.keycloak.token
        fetch(uri, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (!response.ok) {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
              else {
                this.infoNotification("version published Ok")
                this.refreshQuery()
              }

            })
            .catch(error => {
              try {
                const errorObj = JSON.parse(error);
                this.errorNotification(errorObj.error || error);
              } catch (e) {
                this.errorNotification(error);
              }
            })
      } else {
        this.warningNotification("no filter selected")
      }
      this.gridApi.selectedNodes = false
    },
    unPublishVersion(){
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes.length > 0) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/procedures/unpublish/" + node.data.name + "/version/" + node.data.version
        const token = "Bearer " + this.keycloak.token
        fetch(uri, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (!response.ok) {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
              else {
                this.infoNotification("unpublished version Ok")
                this.refreshQuery()
              }

            })
            .catch(error => {
              try {
                const errorObj = JSON.parse(error);
                this.errorNotification(errorObj.error || error);
              } catch (e) {
                this.errorNotification(error);
              }
            })
      } else {
        this.warningNotification("no filter selected")
      }
      this.gridApi.selectedNodes = false
    },
    importNotebook() {
      this.importNotebookVisible = true
    },
    importTemplate() {
      this.importTemplateVisible = true
    },
    getVersionsProcedure(procedureName) {
      const proc = this.procedures.find(p => p.name === procedureName);
      if (proc && proc.versions) {
        return proc.versions.map(ver => ({
          label: ver.version, 
          value: ver.version
        }));

      }
      return [];
    },
    getVersionsProcedure2(procedureName) {
      const proc = this.procedures.filter(p => p.name === procedureName);
      if (proc && proc.versions) {
        return proc.versions.map(ver => ({
          label: ver.version, 
          value: ver.version
        }));

      }
      return [];
    },
    getNotebookId(procedureName, version) {
      const procedure = this.procedures.find(item => item.name === procedureName);
      if (!procedure) return null;

      const versionData = procedure.versions.find(v => v.version === version);
      return versionData ? versionData.notebook.id : null;
    },
    getVersions(procedureName) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/procedures/versions/" + procedureName
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {        
            let versionsOptions = []
            versionsOptions = responseJson.map (ver => ({
              label: ver,
              value: ver
            }))
            return versionsOptions
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getNotebooks() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/procedures/notebooks/procedures"
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.notebooks = []
            this.notebooks = responseJson
          })
          .catch((error) => {
            console.log(error)
          })
    },
    refreshQuery() {
      getClusters(this.keycloak.token)
      getUsers(this.keycloak.token)
      getTemplates(this.keycloak.token)
      this.getNotebooks()
    },
    newFilter() {
      //this.storeClusters.clustersOptions = []
      //this.storeClusters.clustersOptions.push({value:"cluster-M",label:"cluster-M"})
      this.formNewFilter.name = ""
      this.newFilterVisible = true
    },
    editNotebook() {
      this.formSelectNotebook.name = ""
      this.selectNotebookVisible = true
    },
    onCellDoubleClicked(event) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + zeppelinApi + "/#/notebook/" + event.data.id
      window.open(uri, '_blank');
    },
    errorNotification(msg) {
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    },
    warningNotification(msg) {
      notification.open({
        message: 'Warning',
        description: msg,
        class: 'warning-class',
      });
    },
    infoNotification(msg) {
      notification.open({
        message: 'Information',
        description: msg,
        class: 'info-class',
      });
    },
    getNotebooksFromProcedures() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/procedures/notebooks/procedures"
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.formImportNotebook.notebook = []
            this.notebooksFromProcedures = []
            this.notebooksFromProcedures
            this.procedures = responseJson
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getProcedures() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/procedures"
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.formImportNotebook.notebook = []
            this.procedures = []
            this.proceduresFull = []
            this.procedures = responseJson
          })
          .catch((error) => {
            console.log(error)
          })
    },
    cloneNotebook(id, name, cluster) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks/clone/" + id
      const token = "Bearer " + this.keycloak.token
      const cloneNotebook = {
        name: name,
        path: "procedures",
        cluster: cluster
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(cloneNotebook),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then(async response => {
            if (!response.ok) {
              const error = (await response.text() || response.statusText)
              return Promise.reject(error)
            }
            else {
              this.refreshQuery()
            }

          })
          .catch(error => {
            this.errorNotification(error)
          })
    },
    okImportNotebook() {
      this.cloneNotebook(this.formImportNotebook.notebook, this.formImportNotebook.name, this.formImportNotebook.cluster)
      this.formImportNotebook.notebook = ""
      this.formImportNotebook.name = ""
      this.formImportNotebook.user = ""
      this.formImportNotebook.cluster = ""
      this.importNotebookVisible = false
    },
    okImportTemplate() {
      this.cloneNotebook(this.formImportTemplate.notebook, this.formImportTemplate.name, this.formImportTemplate.cluster)
      this.formImportTemplate.notebook = ""
      this.formImportTemplate.name = ""
      this.formImportTemplate.cluster = ""
      this.importTemplateVisible = false
    },
    okNewFilter() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/procedures/notebook"
      const token = "Bearer " + this.keycloak.token
      const newNotebook = {
        name: this.formNewFilter.name,
        path: "procedures/"+this.formNewFilter.name,
        cluster: this.formNewFilter.cluster
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(newNotebook),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
        .then(async response => {
          if (!response.ok) {
            const error = (await response.text() || response.statusText)
            return Promise.reject(error)
          }
          else {
            this.refreshQuery()
          }

        })
        .catch(error => {
          this.errorNotification(error)
        })
      this.newFilterVisible = false
    },
    okEditNotebook() {
      const uri = "https" + "://" + zeppelinApi + "/#/notebook/" + event.data.id
      window.open(uri, '_blank');
    }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

.compilation-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.compilation-progress .ant-spin-text {
  margin-top: 5px;
  font-size: 12px;
}

</style>