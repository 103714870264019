import {apiUrl, TLSEnabled} from "@/config/api";
import {clusterStore} from "@/store/ClusterStore";
import {userStore} from "@/store/UserStore";
import {templateStore} from "@/store/TemplateStore";

export const getClusters = (token) => {
    const store= clusterStore()
    const tls = (TLSEnabled) ? "s" : ""
    const uri = "http" + tls + "://" + apiUrl + "/clusters"
    const tokenBearer = "Bearer " + token
    fetch(uri, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": tokenBearer
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((responseJson) => {
            store.changeClusters(responseJson)
        })
        .catch((error) => {
            console.log(error)
        })    
}

export const getUsers = (token) => {
    const store = userStore()
    const tls = (TLSEnabled) ? "s" : ""
    const uri = "http" + tls + "://" + apiUrl + "/notebooks/users"
    const tokenBearer = "Bearer " + token
    fetch(uri, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": tokenBearer
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((responseJson) => {
            store.changeUsers(responseJson)
        })
        .catch((error) => {
            console.log(error)
        })
}

export const getTemplates = (token) => {
    const store = templateStore()
    const tls = (TLSEnabled) ? "s" : ""
    const uri = "http" + tls + "://" + apiUrl + "/notebooks/templates"
    const tokenBearer = "Bearer " + token
    fetch(uri, {
        headers: {
            'Content-Type': 'application/json',
            "Authorization": tokenBearer
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
        })
        .then((responseJson) => {
            store.changeTemplates(responseJson)
        })
        .catch((error) => {
            console.log(error)
        })
}